<template>
  <e-sidebar
    :title="isEdit ? $t('Editar Forma de Pagamento') : $t('Nova Forma de Pagamento')"
    :show="showSidebar"
    :fetching="loading"
    :saving="saving"
    width="600px"
    @save="savePaymentType"
    @hidden="showSidebar = false"
  >
    <template #content>
      <FormulateForm
        ref="formPaymentType"
        name="formPaymentType"
      >
        <b-row>
          <b-col>
            <FormulateInput
              id="payment-type-name"
              v-model="formPaymentType.name"
              :label="$t('Nome')"
              type="text"
              class="required"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="payment-type-grace-period"
              v-model="formPaymentType.gracePeriod"
              :label="$t('Carência')"
              :instruction="
                $t('Período de carência são os dias entre a venda e o recebimento do dinheiro.')
              "
              type="number"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="payment-type-description"
              v-model="formPaymentType.description"
              :label="$t('Descrição')"
              type="textarea"
            />
          </b-col>
        </b-row>

        <b-card-actions
          action-collapse
          :title="$t('Parâmetros')"
        >
          <b-row>
            <b-col md="12">
              <p class="h4">
                {{ $t('Geral') }}
              </p>
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-not-generate-accounts-payable"
                v-model="formPaymentType.notGenerateAccountsPayable"
                :label="$t('Não gerar Contas a pagar')"
                :instruction="
                  $t(
                    'Quando selecionado, pagamentos que utilizam esta forma de pagamento não irão gerar um Contas a Pagar'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-not-generate-receivable"
                v-model="formPaymentType.notGenerateReceivable"
                :label="$t('Não gerar Recebíveis')"
                :instruction="
                  $t(
                    'Quando selecionado, recebimentos que utilizam esta forma de pagamento não irão gerar um Recebível'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-allow_receivable_partial_write_off"
                v-model="formPaymentType.allowReceivablePartialWriteOff"
                :label="$t('Permite baixa parcial?')"
                :instruction="
                  $t(
                    'Quando selecionado, recebimentos que utilizam esta forma de pagamento poderão realizar baixa parcial'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-is-payroll-deduction"
                v-model="formPaymentType.isPayrollDeduction"
                :label="$t('Usado para desconto em folha?')"
                :instruction="
                  $t(
                    'Quando selecionado, esta forma de pagamento será usada para descontos em folha'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-is-chopp-gift-card"
                v-model="formPaymentType.isChoppGiftCard"
                :label="$t('Cartão de presente de chope?')"
                :instruction="
                  $t(
                    'Quando selecionado, esta forma de pagamento será usada na conversão de Cartão de Presente de chope'
                  )
                "
                type="switch"
              />
            </b-col>

            <b-col md="4">
              <FormulateInput
                id="payment-type-is-automatic-debit"
                v-model="formPaymentType.isAutomaticDebit"
                :label="$t('Débito automático?')"
                :instruction="
                  $t(
                    'Quando selecionado, esta forma de pagamento será usada para em contas a pagar criadas a partir de taxas da conciliação da getnet'
                  )
                "
                type="switch"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col md="12">
              <p class="h4">
                {{ $t('PDV') }}
              </p>
            </b-col>

            <b-col md="4">
              <FormulateInput
                id="payment-type-active-on-pay-box"
                v-model="formPaymentType.activeOnPayBox"
                :label="$t('Utiliza no PDV?')"
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-active-on-pay-box-accouts-payable"
                v-model="formPaymentType.activeOnPayBoxAccountsPayable"
                :label="$t('Utiliza no contas a pagar do PDV?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção na criação de contas a pagar no PDV'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment-type-active-on-pay-box-return"
                v-model="formPaymentType.activeOnPayBoxReturn"
                :label="$t('Utiliza na devolução do PDV?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção na devolução de produtos no PDV'
                  )
                "
                type="switch"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col md="12">
              <p class="h4">
                {{ $t('Nota Fiscal') }}
              </p>
            </b-col>

            <b-col md="4">
              <FormulateInput
                id="payment_type-active_on_invoice_sale"
                v-model="formPaymentType.activeOnInvoiceCategorySale"
                :label="$t('Utiliza na NF do tipo Venda?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment_type-active_on_invoice_return"
                v-model="formPaymentType.activeOnInvoiceCategoryReturn"
                :label="$t('Utiliza na NF do tipo  Devolução?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment_type-active_on_invoice_transference"
                v-model="formPaymentType.activeOnInvoiceCategoryTransference"
                :label="$t('Utiliza na NF do tipo Tranferência?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment_type-active_on_invoice_stock_losses"
                v-model="formPaymentType.activeOnInvoiceCategoryStockLosses"
                :label="$t('Utiliza na NF do tipo Baixa de estoque?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment_type-active_on_invoice_product_bonus"
                v-model="formPaymentType.activeOnInvoiceCategoryProductBonus"
                :label="$t('Utiliza na NF do tipo Bonificação?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal'
                  )
                "
                type="switch"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                id="payment_type-active_on_invoice_store_lending"
                v-model="formPaymentType.activeOnInvoiceCategoryStoreLending"
                :label="$t('Utiliza na NF do tipo Comodato?')"
                :instruction="
                  $t(
                    'Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal'
                  )
                "
                type="switch"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { alerts } from '@/mixins'
import { mapActions } from 'vuex'
import ESidebar from '@/views/components/ESidebar.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

const getInitialForm = () => ({
  id: null,
  name: '',
  description: '',
  gracePeriod: 0,
  activeOnPayBox: false,
  activeOnPayBoxAccountsPayable: false,
  activeOnPayBoxReturn: false,
  generateCheckingAccountEntry: false,
  notGenerateAccountsPayable: false,
  notGenerateReceivable: false,

  isPayrollDeduction: false,
  isAutomaticDebit: false,
  isChoppGiftCard: false,

  allowReceivablePartialWriteOff: false,

  activeOnInvoiceCategorySale: false,
  activeOnInvoiceCategoryReturn: false,
  activeOnInvoiceCategoryTransference: false,
  activeOnInvoiceCategoryStockLosses: false,
  activeOnInvoiceCategoryProductBonus: false,
  activeOnInvoiceCategoryStoreLending: false,
})

export default {
  components: { BRow, BCol, ESidebar, BCardActions },

  mixins: [alerts],
  BCardActions,

  data() {
    return {
      showSidebar: false,
      saving: false,
      loading: false,
      formPaymentType: getInitialForm(),
    }
  },

  computed: {
    isEdit() {
      return !!this.formPaymentType.id
    },
  },

  methods: {
    ...mapActions('app', { appFetchPaymentMethods: 'fetchPaymentMethods' }),
    ...mapActions('pages/settings/financial/paymentType', ['fetchPaymentTypeById']),

    async onShowSidebar(idPaymentType = null) {
      try {
        this.formPaymentType = getInitialForm()
        this.showSidebar = true

        if (idPaymentType) {
          this.loading = true
          this.formPaymentType = await this.fetchPaymentTypeById(idPaymentType)
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    async savePaymentType() {
      try {
        this.saving = true

        // const { id, name, description, gracePeriod, activeOnPayBox } = this.formPaymentType

        // const body = {
        //   id,
        //   name,
        //   description,
        //   gracePeriod,
        //   activeOnPayBox,
        // }

        await this.$http({
          url: '/api/settings/financial/payment-methods',
          method: this.isEdit ? 'PUT' : 'POST',
          data: {
            ...this.formPaymentType,
            activeOnPayBox: !!this.formPaymentType.activeOnPayBox,
            generateCheckingAccountEntry: !!this.formPaymentType.generateCheckingAccountEntry,
            isPayrollDeduction: !!this.formPaymentType.isPayrollDeduction,
            isChoppGiftCard: !!this.formPaymentType.isChoppGiftCard,
          },
        })

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })

        this.$emit('after-save')
        this.showSidebar = false
        await this.appFetchPaymentMethods()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
